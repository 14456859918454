<template>
  <div class="home">
    <Header />
    <h2>Welcome, {{name}}</h2>
    <News />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import News from '@/components/News.vue'
import {ref, onBeforeMount} from 'vue';
export default {
  name: 'Home',
  components: {Header, News},
  
  setup(){
    const name = ref("");

    onBeforeMount(()=>{
      name.value= localStorage.getItem("ploUserName");
    });
    return{
      name
    }
  }
}
</script>
