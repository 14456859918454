<template>
<Accordion v-model:activeIndex="active">
      <AccordionTab header="Add News">
        <div class="text-justify">
            <form @submit.prevent="onSubmit">
            <div>
                <InputText class="p-mb-2 p-mt-2" type="text" placeholder="Title" v-model="form.title" required />
            </div>
            <div>
                <Editor v-model="form.content" editorStyle="height: 320px" placeholder="News content goes here" required/>
            </div>
            <Button label="Publish News" type="submit"  class="p-mt-2" />
            </form>
        </div>
      </AccordionTab>
    </Accordion>
    <transition-group name="p-message" tag="div">
        <Message v-for="msg of messages" :severity="msg.severity" :life="3000" :sticky="false" :key="msg.id">{{msg.content}}</Message>
    </transition-group>
</template>

<script>
import {createNews} from '@/fbDomain'
import {reactive} from 'vue'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {ref} from 'vue';
import Message from 'primevue/message';
import Editor from 'primevue/editor';
import firebase from 'firebase'
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';


export default {
  components: {InputText, Button, Message, Editor, Accordion, AccordionTab},
  setup() {

      const messages = ref([]);
        const count = ref(0);
        const active = ref(1);
        const addMessages = () => {
            messages.value = [
                {severity: 'success', content: 'News Added Successfully', id: count.value++},
            ]
        };

    const form = reactive({title:'', content:''})
    const by = ref()
    const date = ref()

    const onSubmit = async () =>{
      
      await createNews({...form, by: localStorage.getItem("ploUserName"), date: firebase.firestore.Timestamp.fromDate(new Date()) })
      form.title = ''
      form.content = ''
      by.value = ''
      date.value = ''
      active.value = 1
      addMessages()
    }


    return {form,  onSubmit, messages, count, addMessages, active }
  },
}
</script>
<style>

</style>
