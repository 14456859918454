<template>
  <div class="container">
    <h2>Ploutus Latest Updates</h2>
    <!-- <Panel header="Header" class="p-mt-4">
        <template #icons v-if="nonStaff">
            <button class="p-panel-header-icon p-link p-mr-2" >
                <span class="pi pi-pencil"></span>
            </button>
            <button class="p-panel-header-icon p-link p-mr-2" >
                <span class="pi pi-trash"></span>
            </button>
        </template>
        <div class="news-date">Date: 29th Apr 2021</div>
        <div class="news-content">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>
        <div class="news-author"><b>By: Hussain Ali</b></div>
    </Panel> -->
    <div v-if="nonStaff">
      <CreateNews />
    </div>
    

    <div v-for="{id, title, content, date, by} in news" :key="id">
      <Panel :header=title class="p-mt-4">
          <template #icons v-if="nonStaff">
            <router-link class="no-decor" :to="`/editnews/${id}`">
              <button class="p-panel-header-icon p-link p-mr-2" >
                  <span class="pi pi-pencil"></span>
              </button>
            </router-link>
            <button class="p-panel-header-icon p-link p-mr-2" @click="deleteNews(id)">
                <span class="pi pi-trash"></span>
            </button>
          </template>
          <div class="news-date">Date: {{date.toDate().toDateString()}}</div>
          <div class="news-content" v-html="content"></div>
          <div class="news-author"><b>By: {{by}}</b></div>
      </Panel>
    </div>

  </div>
</template>

<script>
// import Header from '@/components/Header.vue'
import Panel from 'primevue/panel';
import {ref} from 'vue';
import {useLoadNews, deleteNews} from '@/fbDomain'
import CreateNews from '@/components/CreateNews'



export default {
  components: {Panel, CreateNews},
  setup() {
    const ploType = ref("");
    ploType.value= localStorage.getItem("ploType");
    const nonStaff = ref()
    ploType.value !== "stf" ? nonStaff.value = true : nonStaff.value = false

    const news = useLoadNews()

    return{news, nonStaff, deleteNews}
  },
}
</script>

<style>
.news-date, .news-author{
    text-align: right;
    margin: 10px 0;
}

.news-content{
    text-align: justify;
}
</style>